

<template>
  <div
    class="receipt-product"
    :class="{selectedProduct: clickedAmount > 0}"
    @click="addProductToSelected()"
  >
    <!-- <div
      style="margin-bottom: 10px;"
      v-if="hideDetails && (orderContent.quantity - orderContent.quantityPayedBeforeClose - orderContent.quantityCanceled- orderContent.quantityChargedToRoom- orderContent.quantityTreated)>0"
    >-->
    <div
      style="margin-bottom: 10px;"
      v-if="(orderContent.quantity - orderContent.quantityCanceled - orderContent.quantityChargedToRoom - orderContent.quantityTreated - orderContent.quantityReceipt)>0"
    >
      <div class="product-header-container">
        <div class="product-header">
          <span
            class="quantity-number"
          >{{orderContent.quantity - orderContent.quantityCanceled - orderContent.quantityChargedToRoom - orderContent.quantityTreated - orderContent.quantityReceipt}}</span>
          <h6 class="product-name">{{orderContent.product.names.translation[language].name}}</h6>
          <h6
            class="price-of-product"
          >{{ getPriceForProductWithSubselections(orderContent, orderContent.quantity - orderContent.quantityCanceled - orderContent.quantityChargedToRoom - orderContent.quantityTreated - orderContent.quantityReceipt).toFixed(2) }}€</h6>
        </div>
        <div
          v-if="clickedAmount > 0"
          class="selected-number-of-product"
        >{{clickedAmount}}/{{orderContent.quantity - orderContent.quantityCanceled - orderContent.quantityChargedToRoom - orderContent.quantityTreated - orderContent.quantityReceipt}}</div>
      </div>

      <SubselectionsOfProductAtOrder
        :language="language"
        :productSubselections="orderContent.productSubselections"
      ></SubselectionsOfProductAtOrder>
      <div
        class="product-comment-container"
        v-if="orderContent.comments!=null && orderContent.comments.trim().length!=0"
      >
        <h6 class="comment-of-product">
          <span class="comment-title">{{ labels.cart.comments[language] }}</span>
          {{orderContent.comments}}
        </h6>
      </div>
    </div>
  </div>
</template>

<script>
import SubselectionsOfProductAtOrder from "./SubselectionsOfProductAtOrder";
export default {
  name: "ReceiptProduct",
  components: { SubselectionsOfProductAtOrder },
  data() {
    return {
      clickedAmount: 0
    };
  },
  props: ["orderContent", "language", "hideDetails", "tableOpening"],
  methods: {
    getPriceForProductWithSubselections(orderContent, quantity) {
      let subselectionsTotalSumPrice = 0;
      if (orderContent.productSubselections && orderContent.productSubselections.length > 0) {
        orderContent.productSubselections.forEach(productSubselection => {
          subselectionsTotalSumPrice = subselectionsTotalSumPrice + productSubselection.price;
        });
      }
      return quantity * orderContent.price + quantity * subselectionsTotalSumPrice;
    },
    selectAllProductsForReceipt() {
      if (this.orderContent.quantity > (this.orderContent.quantityCanceled + this.orderContent.quantityChargedToRoom + this.orderContent.quantityTreated + this.orderContent.quantityReceipt)) {
        this.clickedAmount = 0;
        let selectedOrderProduct = {
          id: this.orderContent.id,
          quantityToBePaid: this.clickedAmount,
          quantityCanceled: this.clickedAmount,
          quantityTreated: this.clickedAmount,
          quantityToBeTransfered: this.clickedAmount,
          quantityReOrder: this.clickedAmount,
          quantityReceipt: this.clickedAmount,
          priceOfProduct: 0
        }
        this.$emit("select-order-product-for-receipt", selectedOrderProduct);

        if (this.clickedAmount < (this.orderContent.quantity - this.orderContent.quantityReceipt - this.orderContent.quantityCanceled - this.orderContent.quantityChargedToRoom - this.orderContent.quantityTreated)) {
          this.clickedAmount = (this.orderContent.quantity - this.orderContent.quantityReceipt - this.orderContent.quantityCanceled - this.orderContent.quantityChargedToRoom - this.orderContent.quantityTreated);
          let priceOfProduct = this.getPriceForProductWithSubselections(this.orderContent, 1);
          let selectedOrderProduct = {
            id: this.orderContent.id,
            quantityToBePaid: this.clickedAmount,
            quantityCanceled: this.clickedAmount,
            quantityToBeTransfered: this.clickedAmount,
            quantityTreated: this.clickedAmount,
            quantityReOrder: this.clickedAmount,
            quantityReceipt: this.clickedAmount,
            priceOfProduct: priceOfProduct
          }
          this.$emit("select-order-product-for-receipt", selectedOrderProduct);
        }
      }
    },
    addProductToSelected() {
      if (this.orderContent.quantity > (this.orderContent.quantityCanceled + this.orderContent.quantityChargedToRoom + this.orderContent.quantityTreated + this.orderContent.quantityReceipt)) {
        if (this.clickedAmount < (this.orderContent.quantity - this.orderContent.quantityReceipt - this.orderContent.quantityCanceled - this.orderContent.quantityChargedToRoom - this.orderContent.quantityTreated)) {
          this.clickedAmount = this.clickedAmount + 1
          let priceOfProduct = this.getPriceForProductWithSubselections(this.orderContent, 1);
          let selectedOrderProduct = {
            id: this.orderContent.id,
            quantityToBePaid: this.clickedAmount,
            quantityCanceled: this.clickedAmount,
            quantityToBeTransfered: this.clickedAmount,
            quantityTreated: this.clickedAmount,
            quantityReOrder: this.clickedAmount,
            quantityReceipt: this.clickedAmount,
            priceOfProduct: priceOfProduct
          }
          this.$emit("select-order-product-for-receipt", selectedOrderProduct);
        } else {
          this.clickedAmount = 0;
          let selectedOrderProduct = {
            id: this.orderContent.id,
            quantityToBePaid: this.clickedAmount,
            quantityCanceled: this.clickedAmount,
            quantityTreated: this.clickedAmount,
            quantityToBeTransfered: this.clickedAmount,
            quantityReOrder: this.clickedAmount,
            quantityReceipt: this.clickedAmount,
            priceOfProduct: 0
          }
          this.$emit("select-order-product-for-receipt", selectedOrderProduct);
        }
      }

    }
  },
  mounted() {

  }
};
</script>

<style scoped>
.receipt-product {
  padding-left: 8px;
}

.selectedProduct {
  background-color: #a9cbff;
  padding-bottom: 2px;
}

.product-with-payed-details-container {
  display: flex;
  flex-direction: column;
}

.product-header-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
  justify-content: space-between;
  align-items: flex-start;
}

.product-header {
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
}

.product-header-already-paid {
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
  align-items: flex-start;
  margin-left: 19px;
  color: #198b4a;
}

.product-header-canceled {
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
  align-items: flex-start;
  margin-left: 19px;
  color: #ce2020;
}

.product-header-treated {
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
  align-items: flex-start;
  margin-left: 19px;
  color: #6f2c91;
}

.quantity-number {
  font-size: 17px;
  font-weight: 900;
  margin-right: 8px;
  color: #198b4a;
}

.quantity-canceled-number {
  font-size: 17px;
  font-weight: 900;
  margin-right: 8px;
  color: #ce2020;
}

.quantity-treated-number {
  font-size: 17px;
  font-weight: 900;
  margin-right: 8px;
  color: #6f2c91;
}

.product-name {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0px;
  margin-right: 6px;
}

.product-name-canceled,
.product-name-treated,
.product-name-already-paid {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-right: 4px;
}

.price-of-product {
  margin-top: 2px;
  font-size: 15px;
  margin-bottom: 0px;
  margin-right: 3px;
}

.price-of-product-already-paid {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0px;
}

.selected-number-of-product {
  font-size: 13px;
  font-weight: 600;
  margin-right: 6px;
  margin-left: 6px;
  margin-top: 2px;
  margin-bottom: 0px;
  color: #353535;
  background-color: rgb(236, 236, 236);
  padding: 0.25rem 0.25rem 0.125rem;
  border-radius: 4px;
}

.product-comment-container {
  padding-left: 19px;
}

.comment-of-product {
  font-size: 14px;
  margin-bottom: 0px;
  color: #393939;
}

.comment-title {
  font-weight: 700;
  margin-right: 6px;
}
</style>