<template>
  <div class="receipt-content-container">
    <div class="receipt-header">
      <div class="receipt-header-info">
        <h6 class="receipt-number">{{receipt.title}} {{receipt.series}}-{{receipt.number}}</h6>
        <!--Invoice info details-->
        <h6
          v-if="receipt.invoiceInfo!=null"
          class="invoice-info"
        >ΑΦΜ πελάτη: {{receipt.invoiceInfo.vat}}</h6>
        <h6
          v-if="receipt.invoiceInfo!=null"
          class="invoice-info"
        >Επωνυμία: {{receipt.invoiceInfo.name}}</h6>
        <h6
          v-if="receipt.invoiceInfo!=null"
          class="invoice-info"
        >Οδός: {{receipt.invoiceInfo.street}}</h6>
        <h6 v-if="receipt.invoiceInfo!=null" class="invoice-info">Πόλη: {{receipt.invoiceInfo.city}}</h6>
        <h6
          v-if="receipt.invoiceInfo!=null"
          class="invoice-info"
        >Τ.Κ.: {{receipt.invoiceInfo.postal_code}}</h6>

        <h6
          v-if="receipt.invoiceInfo!=null && receipt.invoiceInfo.comments!=null"
          class="invoice-info"
        >Παρατηρήσεις: {{receipt.invoiceInfo.comments}}</h6>

        <!--Payment type details-->
        <h6 v-if="receipt.paymentType=='CASH'" class="payment-type">
          Τρ. πληρωμής: Μετρητά
          <font-awesome-icon style="margin-left: 2px; color: #198b4a" icon="money-bill" />
        </h6>
        <h6 v-if="receipt.paymentType=='CARD_TERMINAL'" class="payment-type">
          Τρ. πληρωμής: Κάρτα
          <font-awesome-icon style="margin-left: 2px; color: #17a2b8" icon="credit-card" />
        </h6>
        <h6 v-if="receipt.paymentType=='ON_CREDIT'" class="payment-type">
          Τρ. πληρωμής: Eπί πιστώσει
          <font-awesome-icon style="margin-left: 2px; color: #343a40" icon="history" />
        </h6>
        <h6 v-if="receipt.paymentType=='BANK_ACCOUNT'" class="payment-type">
          Τρ. πληρωμής: Λογ. Τράπεζας
          <font-awesome-icon style="margin-left: 2px; color: #6c757d" icon="hotel" />
        </h6>
        <h6 class="receipt-date">{{getDateForReceipt(receipt.dateOfReceipt)}}</h6>
      </div>

      <div class="receipt-buttons-container">
        <button
          v-if="receipt.paymentType=='ON_CREDIT'"
          @click="sendPaymentToPos()"
          class="pay-to-pos-btn"
        >
          Πληρωμή σε
          <span style="font-size: 11px;">POS</span>
        </button>
        <button @click="printReceipt()" class="print-receipt-again-button">
          <clip-loader
            v-if="printingReceipt"
            :loading="printingReceipt"
            size="15px"
            color="#ffffff"
          />
          <font-awesome-icon id="print-receipt-icon" v-else icon="print" />
        </button>
      </div>
    </div>
    <div class="receipt-body">
      <div v-for="item in receipt.items" :key="item.id" class="item-in-receipt">
        <span class="item-receipt-quantity">{{item.quantity}}</span>
        <span class="item-receipt-name">{{item.itemName}}</span>
        <span class="item-receipt-price">{{item.totalPrice.toFixed(2)}}</span>
      </div>
    </div>
    <div class="receipt-footer">
      <span class="receipt-total-amount">Σύνολο: {{calculateTotalOfReceipt(receipt.items)}}€</span>
    </div>
  </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
export default {
  name: "FinalReceipt",
  components: { ClipLoader },
  data() {
    return {
      printingReceipt: false
    };
  },
  props: ["receipt"],
  methods: {
    getDateForReceipt(dateIso) {
      let date = new Date(dateIso);

      let day = date.getDate();
      day = day < 10 ? `0${day}` : day;
      let month = date.getMonth() + 1;
      month = month < 10 ? `0${month}` : month;

      let hours = date.getHours();
      hours = hours < 10 ? `0${hours}` : hours;
      let minutes = date.getMinutes();
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      let dateString = `${day}-${month}-${date.getFullYear()} ${hours}:${minutes}`

      return dateString;
    },

    calculateTotalOfReceipt(items) {
      let total = 0;
      items.forEach(item => total = total + item.totalPrice);
      return total.toFixed(2);
    },

    async printReceipt() {
      this.printingReceipt = true;
      this.$emit("print-final-receipt", this.receipt.id);
      setTimeout(() => {
        this.printingReceipt = false;
      }, 1600);
    },

    sendPaymentToPos() {
      this.$emit("send-payment-to-pos", this.receipt.id);
    }
  },
  mounted() {

  }
};
</script>

<style scoped>
.receipt-content-container {
  display: flex;
  flex-direction: column;
  margin: 4px 8px 4px 8px;
  padding-bottom: 4px;
  border: 2px solid rgb(92, 184, 92, 0.9);
  border-radius: 8px;
  margin-bottom: 16px;
}

.receipt-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 6px;
  padding-top: 4px;
  border-bottom: 1px solid rgb(94, 94, 94);
  background-color: rgb(92, 184, 92, 0.2);
  border-radius: 8px 8px 0px 0px;
}

.receipt-header-info {
  display: flex;
  flex-direction: column;
}

.receipt-number {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px !important;
  color: rgb(29, 29, 29);
}

.payment-type {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 4px;
  color: rgb(29, 29, 29);
}

.invoice-info {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0px;
  color: rgb(29, 29, 29);
}

.receipt-date {
  font-size: 13px;
  font-weight: 500;
  color: rgb(58, 58, 58);
  margin-bottom: 4px;
}

.receipt-buttons-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.cancel-receipt-button {
  color: white;
  background-color: #ee1228;
  font-size: 13px;
  margin-right: 10px;
}

.print-receipt-again-button {
  padding: 4px 8px 4px 8px;
  border-radius: 6px;
  cursor: pointer;

  color: white;
  background-color: #198b4a;
  font-size: 15px;
  margin-right: 4px;
}

.pay-to-pos-btn {
  padding: 4px 5px 4px 5px;
  border-radius: 6px;
  cursor: pointer;

  color: white;
  background-color: #17a2b8;
  font-size: 12px;
  margin-right: 4px;
  margin-bottom: 8px;
}

.receipt-body {
  display: flex;
  flex-direction: column;
}

.item-in-receipt {
  padding-left: 6px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  padding-bottom: 5px;
  padding-top: 5px;
  border-top: 1px solid rgb(186, 186, 186);
}

.item-receipt-quantity {
  font-size: 17px;
  font-weight: 700;
  margin-right: 5px;
  color: #198b4a;
}

.item-receipt-name {
  font-size: 16px;
  font-weight: 600;
  margin-right: 5px;
  color: rgb(47, 47, 47);
}

.item-receipt-price {
  font-size: 15px;
  font-weight: 500;
  color: rgb(47, 47, 47);
}

.receipt-footer {
  display: flex;
  flex-direction: row;
  border-top: 1px solid rgb(126, 126, 126);
  padding-top: 7px;
  padding-bottom: 4px;
}

.receipt-total-amount {
  font-size: 18px;
  font-weight: 700;
  padding-left: 8px;
  color: rgb(79, 79, 79);
}

.checkbox-for-final {
  width: 28px;
  margin-right: 10px;
  border-radius: 30px;
}
</style>